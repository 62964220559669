<app-header [hidden]="showScanner"></app-header>
<!-- <app-dynamic-no-animate-breadcrumb class="customBreadcrumb" textone="{{ 'BREADCRUMB.PERSONAL_PAGE' | translate }}"></app-dynamic-no-animate-breadcrumb> -->

<div class="userProfileOut" [hidden]="showScanner">
  <div class="profileContent">
    <div class="row align-items-top" *ngIf="custDetail">
      <div class="col-4">
        <!-- <i class="profilePic"></i> -->
        <i class="profilePic" *ngIf="!parentUserImage"></i>
        <i class="profilePicNew" *ngIf="parentUserImage"
          ><img [src]="parentUserImage" alt="profile bg"
        /></i>
      </div>
      <div class="col-8">
        <div class="profileDetails">
          <h6>{{ custDetail?.firstname + " " + custDetail?.lastname }}</h6>
          <p>{{ custDetail?.email }}</p>
          <p>{{ custDetail?.mobile }}</p>
          <p>{{ address }}</p>
        </div>
      </div>
    </div>
    <div class="userProfileBtnsOut">
      <div class="row">
        <div class="col-6">
          <button class="blueBtn mt-3" (click)="goToUserDetail()">
            Quản lý thông tin tài khoản
          </button>
        </div>
        <div class="col-6">
          <button (click)="logOut()" class="blueBtn mt-3">Đăng xuất</button>
        </div>
      </div>
    </div>
  </div>
 
  <section class="prdTilesHorizontalView">
    <div class="tilesView">
      <i></i>
      <div class="row align-items-center">
        <div class="col-6">
          <img
            class="img-fluid"
            src="../../../assets/images/user-profile/grow.png"
            alt="grow"
          />
        </div>
        <div class="col-6">
          <p *ngIf="!grow_chance">Còn <span>0</span></p>
          <p *ngIf="grow_chance">
            Còn <span>{{ grow_chance }}</span>
          </p>
          <p>cơ hội quay số tháng này</p>
          <button routerLink="/grow-spinwheel/11" class="orangeBtn">
            Quay ngay
          </button>
        </div>
      </div>
    </div>
    <div class="tilesView">
      <i></i>
      <div class="row align-items-center">
        <div class="col-6 p-2" style="padding-left: 12px !important">
          <img
            class="img-fluid"
            src="../../../assets/images/prop-sim-iq.png"
            alt="pediasure-logo"
          />
        </div>
        <div class="col-6">
          <p *ngIf="!similac_chance">Còn <span>0</span></p>
          <p *ngIf="similac_chance">
            Còn <span>{{ similac_chance }}</span>
          </p>
          <p>cơ hội quay số tháng này</p>
          <button routerLink="/similac-spinwheel/21" class="orangeBtn">
            Quay ngay
          </button>
        </div>
      </div>
    </div>
    <div class="tilesView">
      <i></i>
      <div class="row align-items-center">
        <div class="col-6">
          <img
            class="img-fluid"
            src="../../../assets/images/pediasure-logo.png"
            alt="pediasure-logo"
          />
        </div>
        <div class="col-6">
          <p *ngIf="!pediasure_chance">Còn <span>0</span></p>
          <p *ngIf="pediasure_chance">
            Còn <span>{{ pediasure_chance }}</span>
          </p>
          <p>cơ hội quay số tháng này</p>
          <button class="orangeBtn" routerLink="/pediasure-spinwheel/21">
            Quay ngay
          </button>
        </div>
      </div>
    </div>
    <div class="tilesView">
      <i></i>
      <div class="row align-items-center">
        <div class="col-6">
          <img
            class="img-fluid"
            src="../../../assets/images/user-profile/logo-ensure-gold-hmb-blue.png"
            alt="logo-ensure-gold-hmb-blue"
          />
        </div>
        <div class="col-6">
          <p *ngIf="!ensure_chance">Còn <span>0</span></p>
          <p *ngIf="ensure_chance">
            Còn <span>{{ ensure_chance }}</span>
          </p>
          <p>cơ hội quay số tháng này</p>
          <button routerLink="/ensure-spin-wheel/21" class="orangeBtn">
            Quay ngay
          </button>
        </div>
      </div>
    </div>
    <div class="tilesView p-0">
      <!-- <i></i> -->
      <!-- <div class="row align-items-center">
        <div class="col-6">
          <img
            class="img-fluid"
            src="../../../assets/images/user-profile/glucerna.png"
            alt="glucerna"
          />
        </div>
        <div class="col-6">
          <p *ngIf="!glucerna_chance">Còn <span>0</span></p>
          <p *ngIf="glucerna_chance">
            Còn <span>{{ glucerna_chance }}</span>
          </p>
          <p>cơ hội quay số tháng này</p>
          <button routerLink="/glucerna-spin-wheel/21" class="orangeBtn">
            Quay ngay
          </button>
        </div>
      </div> -->
      <div class="newGlucerna">
        <img src="../../../assets/images/glucerna-new/glucerna.png" alt="glucerna" />
        <button routerLink="/glucerna-spin-wheel/21" class="orangeBtn">
          Đổi thưởng ngay
        </button>
      </div>
    </div>
  </section>

  <div class="scanCodeHistory">
    <h1>{{ "USER_PROFILE.SCAN_CODE_HISTORY" | translate }}</h1>
    <div class="datePicker">
      <div class="row">
        <div class="col-2">
          <p class="filtetLabel">
            {{ "USER_PROFILE.FILTERED_BY" | translate }}
          </p>
        </div>
        <div class="col-5">
          <form novalidate class="pr">
            <input
              [(ngModel)]="modelDate"
              autocomplete="off"
              class="form-control datePic"
              [placeholder]="currentDate"
              name="date"
              [bsConfig]="{ dateInputFormat: 'MM/YYYY' }"
              (onShown)="onOpenCalendar($event)"
              #dp="bsDatepicker"
              bsDatepicker
              [maxDate]="maxDate"
              (ngModelChange)="dateChanged(modelDate)"
              #inputdata
            />
            <img
              src="../../../assets/images/cal.png"
              class="calImg"
              alt="calender"
              (click)="dp.toggle()"
              [attr.aria-expanded]="dp.isOpen"
              type="button"
            />
          </form>
        </div>
        <div class="col-5">
          <select
            class="dropdown"
            name=""
            (change)="filterProducts($event)"
            id="productList"
          >
            <option value="" disabled="disabled">Chọn sản phẩm</option>
            <option value="false">Similac</option>
            <option value="true" selected="selected">Abbott Grow</option>
            <option value="is_ensure">Ensure</option>
            <option value="is_glucerna">Glucerna</option>
            <option value="is_pediasure">Pediasure</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <ul class="filterLists">
        <li
          *ngFor="let list of transactinList"
          [ngClass]="{
            disabled: list?.isMilk == 'true'
          }"
        >
          <ul class="filterDetails">
            <li>
              <div class="row">
                <div class="col-4">
                  <img
                    *ngIf="list?.itemcode == '2010'"
                    src="../../../assets/images/homesimilaclist1.png"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.itemcode == '2011'"
                    src="../../../assets/images/homesimilaclist2.png"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.itemcode == '2007'"
                    src="../../../assets/images/similacnewbornonelist.png"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.itemcode == '2005'"
                    src="../../../assets/images/similactwolist.png"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.itemcode == '2003'"
                    src="../../../assets/images/similactotalconfortonelist.png"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.itemcode == '2008'"
                    src="../../../assets/images/similacisomillist.png"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.itemcode == '2012'"
                    src="../../../assets/images/similacneosurelist.png"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.itemcode == '2013'"
                    src="../../../assets/images/similaconeproductimage.png"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.itemcode == '2014'"
                    src="../../../assets/images/prd-similac/similac.png"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.itemcode == '2015'"
                    src="../../../assets/images/prd-similac/similac2.png"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.itemcode == '2016'"
                    src="../../../assets/images/prd-similac/similac3.png"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.itemcode == '2017'"
                    src="../../../assets/images/prd-similac/similac4.png"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.itemcode == '2018'"
                    src="../../../assets/images/similacneosurelist.png"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.itemcode == '2019'"
                    src="../../../assets/images/similacneosurelist.png"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.itemcode == '2020'"
                    src="../../../assets/images/similacneosurelist.png"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.itemcode == '2021'"
                    src="../../../assets/images/similacneosurelist.png"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.itemcode == '2001'"
                    src="../../../assets/images/similacalimentumlist.png"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.itemcode == '2003'"
                    src="../../../assets/images/similaceyeq3list.png"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.itemcode == '2006'"
                    src="../../../assets/images/similac-total-comfort-1list.png"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.itemcode == '2009'"
                    src="../../../assets/images/isomilpluslist.png"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.itemcode == '2002'"
                    src="../../../assets/images/similaceye4list.png"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.itemcode == '2004'"
                    src="../../../assets/images/similac-total-comfort-2list.png"
                    alt="filter product"
                  />

                  <img
                    *ngIf="list?.itemcode == '1001'"
                    src="../../../assets/images/abbott-grow-1-list.png"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.itemcode == '1002'"
                    src="../../../assets/images/abbott-grow-2-list.png"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.itemcode == '1003'"
                    src="../../../assets/images/abbott-grow-3-list.png"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.itemcode == '1004'"
                    src="../../../assets/images/abbott-grow-4-list.png"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.itemcode == '1005'"
                    src="../../../assets/images/grow-6-hireslist.png"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.itemcode == '1006'"
                    src="../../../assets/images/grow-3-hireslist.png"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.itemcode == '1007'"
                    src="../../../assets/images/grow1.png"
                    alt="filter product"
                  />

                  <img
                    *ngIf="list?.itemcode == '1008'"
                    src="../../../assets/images/grow2.png"
                    alt="filter product"
                  />

                  <img
                    *ngIf="list?.itemcode == '1009'"
                    src="../../../assets/images/grow3.png"
                    alt="filter product"
                  />

                  <img
                    *ngIf="list?.itemcode == '1010'"
                    src="../../../assets/images/grow4.png"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.itemcode == '3001'"
                    src="../../../assets/images/loyalty-program/lon-Ensure-Armour-FS-850g.png"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.itemcode == '3004'"
                    src="../../../assets/images/loyalty-program/lon-Ensure-Armour-COFFEE-850g.png"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.itemcode == '3002'"
                    src="../../../assets/images/loyalty-program/lon-Ensure-Armour-VANI-LS-850g.png"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.itemcode == '3003'"
                    src="../../../assets/images/loyalty-program/lon-Ensure-Armour-LUA-MACH-850g.png"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.itemcode == '3005'"
                    src="../../../assets/images/loyalty-program/ensure-gold-huong-hanh-nhan-850-g-12.png"
                    alt="filter product"
                  />

                  <img
                    *ngIf="list?.itemcode == '3006'"
                    src="../../../assets/images/loyalty-program/abbott-glucerna-h-ng-vani-850-g.png"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.itemcode == '3007'"
                    src="../../../assets/images/loyalty-program/abbott-glucerna-h-ng-l-a-m-ch-850-g.png"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.itemcode == '4001'"
                    src="../../../assets/images/pediasure-lucky.png"
                    alt="filter product"
                  />
                  <img
                  *ngIf="list?.itemcode == '4002'"
                  src="../../../assets/images/pediasure-ten-plus.png"
                  alt="filter product"
                />
                </div>
               
               
                <div class="col-8">
                  <p
                  
                  >
                    <label>{{ "USER_PROFILE.PRODUCT_CODE" | translate }}:</label
                    ><strong> {{ list?.itemcode }}</strong>
                  </p>
                

                  <p>
                    <label
                      >{{
                        "USER_PROFILE.DATE_OF_SCAN_CODE" | translate
                      }}:</label
                    ><strong>
                      {{ list?.scannedDate | date: "dd/MM/yyyy" }}</strong
                    >
                  </p>
                  <!-- <div *ngIf="firstTimeScan != 'true'"> -->
                  <div [hidden]="list?.first_time_scan == 'true'">
                    <p *ngIf="list?.giftId != 'null'">
                      <label
                        >{{ "USER_PROFILE.EXPIRE_DATE" | translate }}:</label
                      >

                      <strong
                        *ngIf="list?.expiryDate && list?.giftId != 'null'"
                      >
                        {{ list?.expiryDate | date: "dd/MM/yyyy" }}</strong
                      >
                     
                    </p>

                    <p *ngIf="list?.giftId != 'null'">
                      <label
                        >{{ "USER_PROFILE.TURN_STATUS" | translate }}:</label
                      >
                     
                      <strong
                        *ngIf="
                          list?.eligibleForGame == 'true' &&
                          list?.isRedemed == 'false' &&
                          list?.isMilk == 'false'
                        "
                      >
                        Chưa sử dụng</strong
                      >
                      

                      <strong
                        *ngIf="
                          list?.eligibleForGame == 'false' &&
                          list?.isMilk == 'true'
                        "
                      >
                        Hết lượt quy đổi
                      </strong>
                      

                      <strong *ngIf="list?.isMilk == 'true'">
                        Không áp dụng quy đổi
                      </strong>

                     
                      <strong
                        *ngIf="
                          list?.giftId != 'null' &&
                          list?.eligibleForGame == 'true' &&
                          list?.isRedemed == 'true' &&
                          list?.giftId != 'P-00000' &&
                          list?.giftId != 'Pending'
                        "
                      >
                        Đã sử dụng – đã trúng quà
                      </strong>
                     

                      <strong *ngIf="list?.giftId == 'Pending'">
                        Đã sử dụng - Đang cập nhật</strong
                      >

                      <strong *ngIf="list?.giftId == 'P-00000'">
                        Đã sử dụng</strong
                      >
                    
                    </p>
                  </div>
                  <!-- </div> -->
                </div>
              </div>
            </li>
            <li *ngIf="list?.giftId != 'null'">
              <div class="row">
                <div class="col-4">
                  <img
                    *ngIf="
                      list?.giftId == 'P-005678' || list?.giftId == 'P-005678'
                    "
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1615476170790.jpg"
                    alt="filter product"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-005679'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1615476416925.jpg"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-005681'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1615476480880.jpg"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-005591'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1615476603775.png"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-005689'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1615476663989.png"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-005690'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1615476725765.png"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-005612'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1615476768454.png"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-005680'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1615476809091.png"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-005682'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1616494289358.jpg"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-005683'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1616494651240.jpg"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-005685'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1616495254300.jpg"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-005686'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1616495450060.png"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-005687'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1616495525807.jpg"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-005688'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1616495617452.jpg"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-005691'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1616495706296.jpg"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-005692'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1616495848682.jpg"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-005684'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1616495986668.jpg"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-005831'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1628577745709.png"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-005707'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1626083230927.png"
                  />

                  <img
                    *ngIf="list?.giftId == 'P-005819'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1626083901411.png"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-005820'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1626083842247.png"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-005821'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1626083325824.png"
                  />

                  <img
                    *ngIf="list?.giftId == 'P-008651'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1648143587413.png"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-008653'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1648143765500.png"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-008652'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1648143651792.png"
                  />

                  <img
                    *ngIf="list?.giftId == 'P-008647'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1648144126966.png"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-008649'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1648144308121.png"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-008648'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1648144339336.png"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-008711'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1650892961918.png"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-009953'"
                    src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1687934269565.png"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-008712'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1650892994574.png"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-008713'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1650892926234.png"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-008710'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1650892859481.png"
                  />

                  <img
                    *ngIf="list?.giftId == 'P-008791'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1652809478252.png"
                  />

                  <img
                    *ngIf="list?.giftId == 'P-008792'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1652809544214.png"
                  />

                  <img
                    *ngIf="list?.giftId == 'P-008793'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1652809626389.png"
                  />

                  <img
                    *ngIf="list?.giftId == 'P-008650'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1648142990034.png"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-008364'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1640720049062.png"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-008954'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1659204172331.png"
                  />

                  <img
                    *ngIf="list?.giftId == 'P-008861'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1656491108711.png"
                  />

                  <img
                    *ngIf="list?.giftId == 'P-008835'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1656566584471.png"
                  />

                  <img
                    *ngIf="list?.giftId == 'P-008805'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1653919089023.png"
                  />

                  <img
                    *ngIf="list?.giftId == 'P-008804'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1653918994480.png"
                  />

                  <img
                    *ngIf="list?.giftId == 'P-009119'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1661858271989.png"
                  />

                  <img
                    *ngIf="list?.giftId == 'P-009118'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1661858314385.png"
                  />

                  <img
                    *ngIf="list?.giftId == 'P-009117'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1661858358892.png"
                  />

                  <img
                    *ngIf="list?.giftId == 'P-009125'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1661858721532.png"
                  />

                  <img
                    *ngIf="list?.giftId == 'P-009126'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1661858770674.png"
                  />

                  <img
                    *ngIf="list?.giftId == 'P-008836'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1661858810956.png"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-008394'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1644902829461.png"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-008953'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1664468796198.png"
                  />

                  <img
                    *ngIf="list?.giftId == 'P-009321'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1666260284335.png"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-009322'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1666260433466.png"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-009324'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1666260621867.png"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-009323'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1666260750455.png"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-009325'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1666332986101.png"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-009376'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1669365469486.png"
                  />
                  <img
                    *ngIf="list?.giftId == 'P-009375'"
                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1669365424634.png"
                  />
                  <img  *ngIf="list?.giftId == 'P-009402'"  src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1671535523976.png" />
                  <img  *ngIf="list?.giftId == 'P-009401'"  src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1671535812510.png" /> 
                  <img  *ngIf="list?.giftId == 'P-009400'"  src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1671535987326.png" />
                  <img  *ngIf="list?.giftId == 'P-009403'"  src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1671536165667.png" />
                  <img  *ngIf="list?.giftId == 'P-009404'"  src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1671536306137.png" />
                  <img  *ngIf="list?.giftId == 'P-009405'"  src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1671536506507.png" />
                  <img  *ngIf="list?.giftId == 'P-009397'"  src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1671537156037.png" />
                  <img  *ngIf="list?.giftId == 'P-009396'"  src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1671537258524.png" />
                  <img  *ngIf="list?.giftId == 'P-009398'"  src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1671537381395.png" />
                  <img  *ngIf="list?.giftId == 'P-009407'"  src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1671537498275.png" />
                  <img  *ngIf="list?.giftId == 'P-009406'"  src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1671537615302.png" />
                  <img  *ngIf="list?.giftId == 'P-009399'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1674195163123.png" />
                  
                  <img  *ngIf="list?.giftId == 'P-009639'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1679397589696.png" />
                  <img  *ngIf="list?.giftId == 'P-009640'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1679397692515.png" />
                  <img  *ngIf="list?.giftId == 'P-009641'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1679397993874.png" />
                  <img  *ngIf="list?.giftId == 'P-009408'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1679567373655.png" />
                  <img  *ngIf="list?.giftId == 'P-009952'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1687507116845.png" />

                  <img  *ngIf="list?.giftId == 'P-010326'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1695190715206.png" />
                  <img  *ngIf="list?.giftId == 'P-010327'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1695190762784.png" />
                  <img  *ngIf="list?.giftId == 'P-010328'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1695192066565.png" />
                  <img  *ngIf="list?.giftId == 'P-010323'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1695192117930.png" />
                  <img  *ngIf="list?.giftId == 'P-010325'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1695192199868.png" />
                  <img  *ngIf="list?.giftId == 'P-010324'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1695192239951.png" />
                  <img  *ngIf="list?.giftId == 'P-010335'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1698057187963.png" />



                  <img  *ngIf="list?.giftId == 'P-010632'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1703052768711.png" />
                  <img  *ngIf="list?.giftId == 'P-010633'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1703052679313.png" />
                  <img  *ngIf="list?.giftId == 'P-010631'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1703052874724.png" />
                  <img  *ngIf="list?.giftId == 'P-010630'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1703052945441.png" />
                  <img  *ngIf="list?.giftId == 'P-010629'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1703053007188.png" />
                  <img  *ngIf="list?.giftId == 'P-010640'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1703053127863.png" />
                  <img  *ngIf="list?.giftId == 'P-010641'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1703053199288.png" />
                  <img  *ngIf="list?.giftId == 'P-009119'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1703001872186.png" />
                  <img  *ngIf="list?.giftId == 'P-010644'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1703053376912.png" />
                  <img  *ngIf="list?.giftId == 'P-010646'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1703053462652.png" />



                  <img  *ngIf="list?.giftId == 'P-010818'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1710752630694.png" />
                  <img  *ngIf="list?.giftId == 'P-010819'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1710752826275.png" />
                  <img  *ngIf="list?.giftId == 'P-010820'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1710753032529.png" />
                  <img  *ngIf="list?.giftId == 'P-010824'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1710753237124.png" />
                  <img  *ngIf="list?.giftId == 'P-010825'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1710753345593.png" />
                  <img  *ngIf="list?.giftId == 'P-010827'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1710753463442.png" />
                  <img  *ngIf="list?.giftId == 'P-010826'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1710753597271.png" />
                  <img  *ngIf="list?.giftId == 'P-010817'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1710753691791.png" />
                  <img  *ngIf="list?.giftId == 'P-009942'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1710753691791.png" />
                  <img  *ngIf="list?.giftId == 'P-010202'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1690437364456.png" />
                  <img  *ngIf="list?.giftId == 'P-010867'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1711971001696.png" />

                  <img  *ngIf="list?.giftId == 'P-013525'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1718875647222.png" />
                  <img  *ngIf="list?.giftId == 'P-013524'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1718875683476.png" />
                  <img  *ngIf="list?.giftId == 'P-013528'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1718875719362.png" />
                  <img  *ngIf="list?.giftId == 'P-013523'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1718875757310.png" />
                  <img  *ngIf="list?.giftId == 'P-013522'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1718875799447.png" />
                  <img  *ngIf="list?.giftId == 'P-013521'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1718875835238.png" />
                  <img  *ngIf="list?.giftId == 'P-012005'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1718875866782.png" />
                  <img  *ngIf="list?.giftId == 'P-012006'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1718875913255.png" />
                  <img  *ngIf="list?.giftId == 'P-012004'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1718875945798.png" />

                  <img  *ngIf="list?.giftId == 'P-016724'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1724395026385.png" />
                  
                  <img  *ngIf="list?.giftId == 'P-010659'"  src="https://sttarter-live-legacy.s3.ap-southeast-1.amazonaws.com/f148dba495e5391a7790087cbb5fc3e2-1727172552555.png" />

                </div>

                <div class="col-8">
                  <h6 *ngIf="list?.giftId == 'P-010659'">Bình lắc cao cấp</h6>

                  <h6 *ngIf="list?.giftId == 'P-016724'">Nhiệt kế điện tử</h6>

                  <h6 *ngIf="list?.giftId == 'P-013525'">Túi đeo chéo Hươu Grow</h6>
                  <h6 *ngIf="list?.giftId == 'P-013524'">Gối cổ</h6>
                  <h6 *ngIf="list?.giftId == 'P-013528'">Nón bảo hiểm Similac cho bé</h6>
                  <h6 *ngIf="list?.giftId == 'P-013523'">Bộ sticker PDS</h6>
                  <h6 *ngIf="list?.giftId == 'P-013522'">Combo Tập vẽ-Bút tô màu-Túi đựng bút cho bé</h6>
                  <h6 *ngIf="list?.giftId == 'P-013521'">Thú bông Khủng Long PediaSure</h6>
                  <h6 *ngIf="list?.giftId == 'P-012005'">Dụng cụ tập lưng cao cấp</h6>
                  <h6 *ngIf="list?.giftId == 'P-012006'">Ly khuấy cao cấp</h6>
                  <h6 *ngIf="list?.giftId == 'P-012004'">Máy đo huyết áp Sino</h6>


                  <h6 *ngIf="list?.giftId == 'P-010632'">Balo hươu Grow nhỏ</h6>
                  <h6 *ngIf="list?.giftId == 'P-010633'">Balo Hươu Grow</h6>
                  <h6 *ngIf="list?.giftId == 'P-010631'">Balo túi đeo chéo 2 trong 1</h6>
                  <h6 *ngIf="list?.giftId == 'P-010630'">Gối cổ hình chữ U PediaSure</h6>
                  <h6 *ngIf="list?.giftId == 'P-010629'">Khủng long lớn nam châm Pediasure</h6>
                  <h6 *ngIf="list?.giftId == 'P-010640'">Phiếu mua sắm 80,000 VND</h6>
                  <h6 *ngIf="list?.giftId == 'P-010641'">Bộ bình và ly thủy tinh cao cấp</h6>
                  <h6 *ngIf="list?.giftId == 'P-009119'">Cân sức khỏe điện tử</h6>
                  <h6 *ngIf="list?.giftId == 'P-010644'">Ly sứ cao cấp</h6>
                  <h6 *ngIf="list?.giftId == 'P-010646'">Cân điện tử</h6>



                  <h6 *ngIf="list?.giftId == 'P-010818'">Hươu Grow đeo cờ Mỹ</h6>
                  <h6 *ngIf="list?.giftId == 'P-010819'">Gối ôm Hươu Grow</h6>
                  <h6 *ngIf="list?.giftId == 'P-010820'">Bộ gối mền</h6>
                  <h6 *ngIf="list?.giftId == 'P-010824'">Bàn chải điện khủng long Pediasure</h6>
                  <h6 *ngIf="list?.giftId == 'P-010825'">Dù cầm tay cho bé Pediasure</h6>
                  <h6 *ngIf="list?.giftId == 'P-010827'">Túi du lịch cao cấp</h6>
                  <h6 *ngIf="list?.giftId == 'P-010826'">Khăn tắm cao cấp</h6>
                  <h6 *ngIf="list?.giftId == 'P-010817'">Gối cao su non</h6>
                  <h6 *ngIf="list?.giftId == 'P-009942'">Bình nước Lock&Lock 1.2L</h6>
                  <h6 *ngIf="list?.giftId == 'P-010202'">Túi sức khoẻ Glucerna</h6>
                  <h6 *ngIf="list?.giftId == 'P-010867'">Túi đa năng Glucerna</h6>



                  <h6 *ngIf="list?.giftId == 'P-010335'">Bộ khung thành bóng rổ</h6>
                  <h6 *ngIf="list?.giftId == 'P-010326'">Nón gấu cho bé</h6>
                  <h6 *ngIf="list?.giftId == 'P-010327'">Túi tote canvas cho mẹ</h6>
                  <h6 *ngIf="list?.giftId == 'P-010328'">Bộ dụng cụ học tập bút màu đa năng PediaSure</h6>
                  <h6 *ngIf="list?.giftId == 'P-010323'">Bàn chải điện cho bé PediaSure</h6>
                  <h6 *ngIf="list?.giftId == 'P-010325'">Gối cao su non</h6>
                  <h6 *ngIf="list?.giftId == 'P-010324'">Máy Massage cầm tay đa năng </h6>

                  <h6 *ngIf="list?.giftId == 'P-009376'">Gấu Teddy Similac</h6>
                  <h6 *ngIf="list?.giftId == 'P-009375'">Túi đi sinh Similac </h6>

                  <h6 *ngIf="list?.giftId == 'P-008394'">Bình nước Grow</h6>
                  <h6 *ngIf="list?.giftId == 'P-008953'">
                    Áo choàng hươu Grow
                  </h6>
                  <h6 *ngIf="list?.giftId == 'P-008836'">Xe đạp gấp Grow</h6>

                  <h6 *ngIf="list?.giftId == 'P-009126'">
                    Bình nước thủy tinh bọc nhựa
                  </h6>

                  <h6 *ngIf="list?.giftId == 'P-009125'">
                    Ly thủy tinh Luminarc
                  </h6>

                  <h6 *ngIf="list?.giftId == 'P-009117'">Đĩa tập xoay eo</h6>

                  <h6 *ngIf="list?.giftId == 'P-009118'">
                    Đia massage hồng ngoại
                  </h6>

                  <div class="col-8">
                    <h6 *ngIf="list?.giftId == 'P-009119'">
                      Cân sức khỏe điện tử
                    </h6>

                    <h6 *ngIf="list?.giftId == 'P-005678'">
                      Túi đi sinh cho mẹ
                    </h6>
                    <h6 *ngIf="list?.giftId == 'P-005679'">
                      Set 2 ly thuỷ tinh Lock & Lock
                    </h6>
                    <h6 *ngIf="list?.giftId == 'P-005681'">
                      Bộ xếp hình lắp ráp sáng tạo
                    </h6>
                    <h6 *ngIf="list?.giftId == 'P-005591'">
                      Bộ đồ chơi xúc cát
                    </h6>
                    <h6 *ngIf="list?.giftId == 'P-005689'">
                      Ly sứ Abbott Grow
                    </h6>
                    <h6 *ngIf="list?.giftId == 'P-005690'">
                      Bộ đồ chơi câu cá
                    </h6>
                    <h6 *ngIf="list?.giftId == 'P-005612'">
                      Bộ đồ chơi bóng rổ
                    </h6>
                    <h6 *ngIf="list?.giftId == 'P-005680'">
                      Bộ bình và dĩa ăn cho bé
                    </h6>
                    <h6 *ngIf="list?.giftId == 'P-005682'">
                      Túi đi sinh cho mẹ
                    </h6>
                    <h6 *ngIf="list?.giftId == 'P-005683'">Gấu trạng nhí</h6>
                    <h6 *ngIf="list?.giftId == 'P-005685'">
                      Bộ đồ chơi xúc cát
                    </h6>
                    <h6 *ngIf="list?.giftId == 'P-005686'">Balo kéo Similac</h6>
                    <h6 *ngIf="list?.giftId == 'P-005687'">
                      Bảng học nam châm
                    </h6>
                    <h6 *ngIf="list?.giftId == 'P-005688'">Bộ bàn ghế học</h6>
                    <h6 *ngIf="list?.giftId == 'P-005691'">
                      Xe Scooter 3 trong 1
                    </h6>
                    <h6 *ngIf="list?.giftId == 'P-005692'">
                      Bộ bowling đa năng
                    </h6>
                    <h6 *ngIf="list?.giftId == 'P-005684'">
                      Bộ đồ chơi bé tập làm hoạ sĩ
                    </h6>
                    <h6 *ngIf="list?.giftId == 'P-005707'">
                      Balo bà bầu Similac Mom
                    </h6>

                    <h6 *ngIf="list?.giftId == 'P-005831'">Thang thăng bằng</h6>

                    <h6 *ngIf="list?.giftId == 'P-005819'">
                      Gối ôm nâu Similac
                    </h6>
                    <h6 *ngIf="list?.giftId == 'P-005820'">Gối chặn</h6>
                    <h6 *ngIf="list?.giftId == 'P-005821'">
                      Balo mẫu giáo Similac
                    </h6>

                    <h6 *ngIf="list?.giftId == 'P-008651'">
                      Máy massage hồng ngoại đa năng
                    </h6>
                    <h6 *ngIf="list?.giftId == 'P-008653'">Máy đo huyết áp</h6>
                    <h6 *ngIf="list?.giftId == 'P-008652'">
                      Ba lô thể thao tiện dụng
                    </h6>

                    <h6 *ngIf="list?.giftId == 'P-008647'">
                      Cân điện tử sức khoẻ
                    </h6>
                    <h6 *ngIf="list?.giftId == 'P-008648'">Nhiệt kế điện tử</h6>
                    <h6 *ngIf="list?.giftId == 'P-008649'">
                      Thiết bị đo glucose liên tục Freestyle Libre
                    </h6>

                    <h6 *ngIf="list?.giftId == 'P-008710'">
                      Cốc thuỷ tinh cao cấp
                    </h6>
                    <h6 *ngIf="list?.giftId == 'P-008711'">
                      Bình nước khủng long
                    </h6>
                    <h6 *ngIf="list?.giftId == 'P-009953'">
                      Balo túi đeo chéo 2 trong 1 Pediasure
                    </h6>
                    <h6 *ngIf="list?.giftId == 'P-008712'">
                      Khủng long nhồi bông
                    </h6>
                    <h6 *ngIf="list?.giftId == 'P-008713'">Vali khủng long</h6>

                    <h6 *ngIf="list?.giftId == 'P-008791'">
                      Bình giữ nhiệt Lock & Lock 330ml (màu ngẫu nhiên)
                    </h6>

                    <h6 *ngIf="list?.giftId == 'P-008792'">
                      Bình giữ nhiệt Lock & Lock 400ml
                    </h6>

                    <h6 *ngIf="list?.giftId == 'P-008793'">
                      Bình nước Lock & Lock 500ml
                    </h6>
                    <h6 *ngIf="list?.giftId == 'P-008650'">
                      Ly thuỷ tinh cao cấp
                    </h6>
                    <h6 *ngIf="list?.giftId == 'P-008364'">Nón báo hiểm</h6>

                    <h6 *ngIf="list?.giftId == 'P-008954'">
                      Thước đo chiều cao cho bé
                    </h6>

                    <h6 *ngIf="list?.giftId == 'P-008861'">Gối chữ U</h6>

                    <h6 *ngIf="list?.giftId == 'P-008835'">Balo hươu Grow</h6>

                    <h6 *ngIf="list?.giftId == 'P-008805'">
                      Đồng hồ gỗ Similac
                    </h6>

                    <h6 *ngIf="list?.giftId == 'P-008804'">
                      Ghế đọc sách sô pha
                    </h6>

                    <h6 *ngIf="list?.giftId == 'P-009321'">Bình nước Grow</h6>
                    <h6 *ngIf="list?.giftId == 'P-009322'">
                      Khăn choàng tắm cho bé
                    </h6>
                    <h6 *ngIf="list?.giftId == 'P-009324'">Bộ xếp hình Lego</h6>
                    <h6 *ngIf="list?.giftId == 'P-009323'">
                      Bộ tô màu sáp Similac
                    </h6>
                    <h6 *ngIf="list?.giftId == 'P-009325'">Túi tote canvas</h6>
                    <h6 *ngIf="list?.giftId == 'P-009402'">Túi đeo chéo</h6>
                    <h6 *ngIf="list?.giftId == 'P-009401'">Bộ bàn ghế bảng học nam châm</h6>
                    <h6 *ngIf="list?.giftId == 'P-009400'">Mền Hươu</h6>
                    <h6 *ngIf="list?.giftId == 'P-009403'">Gối cổ biến hình 2 trong 1 </h6>
                    <h6 *ngIf="list?.giftId == 'P-009404'">Khăn tắm nhỏ </h6>
                    <h6 *ngIf="list?.giftId == 'P-009405'">Khăn tắm cho bé </h6>
                    <h6 *ngIf="list?.giftId == 'P-009397'">Vali kéo khủng long</h6>
                    <h6 *ngIf="list?.giftId == 'P-009396'">Túi đeo chéo</h6>
                    <h6 *ngIf="list?.giftId == 'P-009398'">Gối cổ  khủng long bông</h6>
                    <h6 *ngIf="list?.giftId == 'P-009407'">Ly thủy tinh cao cấp</h6>
                    <h6 *ngIf="list?.giftId == 'P-009406'">Máy đo huyết áp</h6>
                    <h6 *ngIf="list?.giftId == 'P-009399'">Hươu bông Grow Cao</h6>

                    <h6 *ngIf="list?.giftId == 'P-009639'">Bộ gối mền Hươu Grow</h6>
                    <h6 *ngIf="list?.giftId == 'P-009640'">Balo hươu Grow</h6>
                    <h6 *ngIf="list?.giftId == 'P-009641'">Gấu teddy baby</h6>
                    <h6 *ngIf="list?.giftId == 'P-009408'">Bình giữ nhiệt 500ml</h6>
                    <h6 *ngIf="list?.giftId == 'P-009952'">Khăn tắm sợi tre cho bé</h6>



                   
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </li>
      </ul>

      <h4 class="text-center my-3" *ngIf="transactinList?.length == 0">
        {{ norecords }}
      </h4>
     
    </div>
  </div>
</div>
<div class="qrCodeScan" *ngIf="showCamera">
  <img
    class="roundedImage"
    src="../../../assets/images/qrscan.png"
    alt="qr scan"
  />

  <div class="text-center">
    <button class="orangeBtn" (click)="openModal(QR2Popup)">
      {{ "SCAN_QR_CODE_NOW" | translate }}</button
    ><br />
  </div>
</div>

<ng-template #QR2Popup>
  <div class="modal-body" [hidden]="showScanner">
    <section class="bonusRedemptionOut">
      <button
        type="button"
        class="close pull-right closeBtn"
        aria-label="Close"
        (click)="modalRef.hide()"
      >
        <span aria-hidden="true">&times;</span>
      </button>

      <h1>{{ "BONUS_REDEMPTION.BONUS_REDEMPTION_TITLE" | translate }}</h1>
      <div class="row">
        <div class="col-6">
          <div class="scanDetails">
            <img src="../../../assets/images/scan_code.png" alt="scan code" />
            <p>{{ "BONUS_REDEMPTION.QR_CODE_TYPE2" | translate }}</p>
          </div>
        </div>
        <div class="col-6">
          <div class="scanDetails">
            <img
              src="../../../assets/images/mobile_scan.png"
              alt="mobile code"
            />
            <p>{{ "BONUS_REDEMPTION.ASSITIVE_APPLICATIONS" | translate }}</p>
          </div>
        </div>
      </div>
      <button class="orangeBtn" (click)="onClickScanner()">
        {{ "SCAN_QR_CODE_NOW" | translate }}
      </button>
      <div class="questionOut">
        <h6>{{ "BONUS_REDEMPTION.QUESTIONS" | translate }}</h6>
        <p>
          {{ "BONUS_REDEMPTION.CUSTOMER_SERVICE1" | translate }}<br />
          {{ "BONUS_REDEMPTION.CUSTOMER_SERVICE2" | translate }}
        </p>
        <a class="telNum" href="tel:19001519">19001519 </a>
      </div>
      <p class="py-4" *ngIf="prodCategory == 'Ensure'">ENS-C-506-21</p>
      <p class="py-4" *ngIf="prodCategory == 'Glucerna'">GLU-C-200-21</p>
      <p class="py-4" *ngIf="prodCategory == 'PediaSure'">PED-C-76-22</p>
    </section>
  </div>
</ng-template>

<div [hidden]="!showScanner">
  <div class="video-row" fxFlex="100">
    <video class="video" #video autoplay playsinline></video>
  </div>
  <div fxFlex="100" style="display: none">
    <canvas class="video" id="canvas" #canvas></canvas>
  </div> 
  <button class="cameraBtn" (click)="closeScanner()">Đóng</button>
</div>

<app-dynamic-footer
  code="COR-C-21-21"
  [hidden]="showScanner"
></app-dynamic-footer>
<app-nav-menu [hidden]="showScanner"></app-nav-menu>
